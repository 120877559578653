import React, { useEffect, useState } from "react";
import "./SectionOne.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { LiaMaleSolid } from "react-icons/lia";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import axios from "axios";
import { API_BASE_URL } from "../Api";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SectionOne = ({ page, setPage }) => {
  const [propertyList, setPropertyList] = useState([]);
  const swiperRef = React.useRef(null);

  useEffect(() => {
    detailsFetcher();
  }, []);

  const detailsFetcher = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}hostels`);
      setPropertyList(response.data);
    } catch (error) {
      console.error("Failed to fetch property list", error);
    }
  };

  const sendingData = (dd) => {
    setPage([dd]);
  };

  // Stop autoplay on hover
  const stopAutoplay = () => {
    swiperRef.current.swiper.autoplay.stop();
  };

  // Start autoplay on mouse leave
  const startAutoplay = () => {
    swiperRef.current.swiper.autoplay.start();
  };

  return (
    <div className="sec_1_main_page container">
      <h3 className="text-center sec_1_main_page_title">
        <span className="color">Chennai's</span> Most Secure PG Homes <br /> for
        Peaceful Living
      </h3>
      <div
        className="sec_1_locations_card mt-3 pt-2"
        data-aos="fade-up"
        onMouseEnter={stopAutoplay}
        onMouseLeave={startAutoplay}
      >
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={3}
          spaceBetween={20}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            1024: { slidesPerView: 3, spaceBetween: 15 },
            853: { slidesPerView: 2, spaceBetween: 15 },
            390: { slidesPerView: 1, spaceBetween: 10 },
          }}
        >
          {propertyList.map((item) => (
            <SwiperSlide>
              <div className="p-2 sec_1_box" key={item.id}>
                <Link
                  className="text-dark"
                  to="/room_details"
                  onClick={() => sendingData(item)}
                >
                  <div className="hostel_img">
                    <img
                      src={`http://localhost:4000/${item.hostelImage}`}
                      alt="img"
                    />
                  </div>
                  <div className="sec_1_box_body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4 className="sec_1_box_title fst-italic">
                          <span className="color">{item.brand}</span> Hi Tech
                        </h4>
                        <h6 className="sec_1_box_text">{item.address}</h6>
                      </div>
                      <div className="field_btn">
                        <div className="btn_male">
                          <LiaMaleSolid className="mb-1" />
                          {item.gender}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="d-flex justify-content-between">
                    <div className="sec_1_box_body">
                      <h6 className="sec_1_box_text">Rent starts from</h6>
                      <h5 className="sec_1_box_title">
                        ₹ {item.startingRent}/-
                      </h5>
                    </div>
                    <div className="field_btn mt-3">
                      <button className="btn_booknow">View More</button>
                    </div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SectionOne;
