import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Assets from "../../Assets/Assets";
import { IoCallOutline } from "react-icons/io5";

const Header = () => {
  const closeNavbar = () => {
    const navbar = document.getElementById("navbarNav");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg position-sticky top-0 z-3">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Assets.main_logo} className="logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav nav_list ms-auto me-auto gap-3">
              <li className="nav-item">
                <Link
                  className="nav-link hover_shadow"
                  to="/"
                  onClick={closeNavbar}
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link hover_shadow"
                  to="/about_us"
                  onClick={closeNavbar}
                >
                  ABOUT US
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link hover_shadow"
                  to="/blogs"
                  onClick={closeNavbar}
                >
                  BLOGS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact_us"
                  className="nav-link hover_shadow"
                  onClick={closeNavbar}
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>
          <h5 className="color m-0">
            <IoCallOutline className="fs-3 mb-1 shake-icon" /> +91 98848 25258{" "}
          </h5>
        </div>
      </nav>
    </>
  );
};

export default Header;
