import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Title from "../../Title/Title";

const BlogsDetail = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const location = useLocation(); // Access state passed via Link
  const blog = location.state?.blog;

  return (
    <>
      <Title title={"Blog"} us={"Details"} />
      <div className="container my-5 p-4 border shadow-lg rounded">
        {blog ? (
          <div className="d-flex flex-column gap-5">
            <div className="row g-4 align-items-center">
              <div className="col-lg-6 text-center">
                <img
                  src={`http://localhost:4000/Images/${blog.image}`}
                  alt={blog.title}
                  className="img-fluid rounded"
                  style={{ height: "350px" }}
                />
              </div>
              <div className="col-lg-6 text-center">
                <h2 className="fs-1 fw-bold">{blog.title}</h2>
                <div className="my-3">
                
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p className="text-justify fs-5">{blog.content}</p>
                <hr />
                <p className="text-muted">
                  {blog.author} · {new Date(blog.createdAt).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center">No blog post data available.</p>
        )}
      </div>
    </>
  );
};

export default BlogsDetail;
