import React from "react";
import "./HeroSection.css";
import Assets from "../../Assets/Assets";
import CountUp from "react-countup";

const HeroSection = () => {

  return (
    <header>
      <div className="hero-container" id="top">
        <div className="hero-grid">
          <div className="hero-content" data-aos="fade-right">
            <h1 className="hero-title">
              Best-In-Class PG for Men and Women in Prime Locations
            </h1>
            <form className="hero-form">
              <input
                type="text"
                className="input_text"
                placeholder="Enter your location"
              />
              <button className="search_btn">Search</button>
            </form>
            <div className="hero-info-grid" data-aos="zoom-out-right">
              <div className="info-box">
                <h3 className="info-title">
                  <CountUp start={0} end={50000} duration={5} separator=","/>
                </h3>
                <p className="info-text text-dark">Delighted Customers</p>
              </div>
              <div className="info-box">
                <h3 className="info-title">
                  <CountUp start={0} end={45000} duration={4}/>
                </h3>
                <p className="info-text text-dark">Best Feedbacks</p>
              </div>
              <div className="info-box">
                <h3 className="info-title">
                  <CountUp start={0} end={10000} duration={5}/>
                </h3>
                <p className="info-text text-dark">Cities & Counting</p>
              </div>
            </div>
          </div>
          <div className="hero-image">
            <img src={Assets.frontlook} alt="people_look" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;
