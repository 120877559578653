import React from "react";
import Assets from "../../../Assets/Assets";
import "./AboutUs.css";
import Title from "../../Title/Title";

const AboutUs = () => {
  return (
    <div className="about_us">
      <div className="position-relative">
        <div>
          <img
            src={Assets.bck_2}
            className="title_bck_img img-fluid"
            alt="background"
          />
          <Title title={"About"} us={"Us"} />
        </div>
      </div>
      <section className="sec_2_about_us section_gap">
        <div className="container">
          <div className="sec_about_us_content" data-aos="fade-up">
            <div className="col-lg-6 col-12">
              <div className="sec_2_about_us_info mt-4">
                <h1 className="content pb-3">
                  <span className="color">Take</span> Rest and make it best
                </h1>
                <p className="sub_content">
                  Hostel beds offer a practical solution for travelers and
                  students seeking affordable and communal lodging. They are
                  typically designed for functionality and comfort, providing a
                  place to rest and rejuvenate during a busy trip or academic
                  schedule.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <img src={Assets.bed_3} className="img-fluid" alt="Hostel bed" />
            </div>
          </div>
        </div>
      </section>
      <section className="section_gap">
        <div className="container">
          <div className="sec_about_us_content color_sec" data-aos="fade-up">
            <div className="col-lg-6 col-12">
              <div className="sec_2_about_us_info mt-4">
                <h1 className="content pb-3 text-light fw-bold">
                  <span className="text-dark">Comfortable Stays</span> With
                  Delicious Food
                </h1>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <p className="text-light">
                At Brindhavanam Hostel, we provide more than just a place to
                stay—we offer a home away from home. Our spacious and
                comfortable rooms are designed to meet your needs, while our
                delicious and nutritious food facilities ensure you feel
                nourished every day. Whether you're a student or working
                professional, Brindhavanam Hostel is the perfect place to live,
                eat, and thrive.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_3_about_us section_gap">
        <div className="container">
          <div className="sec_about_us_content" data-aos="fade-up">
            <div className="col-lg-5 col-12">
              <img
                src={Assets.appartment}
                className="img-fluid"
                alt="Apartment"
              />
            </div>
            <div className="col-lg-6 col-12">
              <div className="sec_2_about_us_info mt-4">
                <h1 className="content pb-3">
                  <span className="color">Discover</span> Your Ideal Apartment
                  with Us
                </h1>
                <p className="sub_content">
                  Our properties feature modern amenities, convenient locations,
                  and a commitment to comfort and quality. With a focus on
                  exceptional service, we aim to make your apartment search
                  seamless and enjoyable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section_gap">
        <div className="container">
          <div className="sec_about_us_content color_sec" data-aos="fade-up">
            <div className="col-lg-6 col-12">
              <div className="sec_2_about_us_info mt-4">
                <h1 className="content pb-3 text-light fw-bold">
                  <span className="text-dark">It's Uniques</span> It's our
                  signatur style
                </h1>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <p className=" text-light">
                Brindhavanam Hi-Tech: The buildings that become our residences
                come in different shapes and sizes. And aren't we glad about
                that? That way, we can retain their distinct architecture while
                infusing them with our design philosophy. So that every
                Brindhavanam Hi-Tech residence stays unique and yet is uniquely
                Brindhavanam Hi-Tech.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section_gap">
        <div className="container">
          <div className="sec_about_us_content" data-aos="fade-up">
            <div className="col-lg-6 col-12">
              <div className="sec_2_about_us_info mt-4">
                <h1 className="content pb-3">
                  <span className="color">Meet</span> your new friends from
                  multiple countries
                </h1>
                <p className="sub_content">
                  Friendship is a cherished bond that offers support and
                  companionship through life's ups and downs. It thrives on
                  trust, understanding, and shared experiences, creating a
                  strong connection that can weather any storm.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <img
                src={Assets.people}
                className="img-fluid"
                alt="People from various countries"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
