import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Assets from "../../../Assets/Assets";
import Title from "../../Title/Title";
import axios from "axios";
import "./Contact.css";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    number: false,
    message: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {
      name: formData.name === "",
      email: !/\S+@\S+\.\S+/.test(formData.email),
      number: formData.number.length !== 10,
      message: formData.message === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        await axios.post("http://localhost:4000/api/contact", formData);
        alert("Form submitted successfully!");
        resetForm();
      } catch (error) {
        console.error("Error saving contact", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      number: "",
      message: "",
    });
    setErrors({
      name: false,
      email: false,
      number: false,
      message: false,
    });
  };

  return (
    <div className="position-relative">
      <div>
        <img
          src={Assets.bck_2}
          className="title_bck_img img-fluid"
          alt="background_img"
        />
        <Title title={"Contact"} us={"Us"} />
      </div>
      <section className="container my-5 border p-5 shadow-lg p-3 mb-5 bg-body rounded">
        <div className="text-center mb-5">
          <h2 className="font-weight-bold contact_page_title fst-italic">
            Get In Touch With Us
          </h2>
          <p className="d-none d-md-block text-muted">
            For more information about our PG's & Guest house, please feel free
            to drop us an email.
          </p>
        </div>
        <div className="contact_page_form">
          <div className="contact_page_side_info">
            <div className="d-flex align-items-start mb-4">
              <div className="icon_outer me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#FF6347"
                >
                  <path d="M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z" />
                </svg>
              </div>
              <div>
                <h5 className="font-weight-bold">Locations</h5>
                <p>Vadapalani / Ramapuram / Kodambakkam</p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <div className="icon_outer me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#FF6347"
                >
                  <path d="M760-480q0-117-81.5-198.5T480-760v-80q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480h-80Zm-160 0q0-50-35-85t-85-35v-80q83 0 141.5 58.5T680-480h-80Zm198 360q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                </svg>
              </div>
              <div>
                <h5 className="font-weight-bold">Phone</h5>
                <p>Mobile: +(91) 98848 25258 / 98402 34475</p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <div className="icon_outer me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#FF6347"
                >
                  <path d="M560-520h280v-200H560v200Zm140-50-100-70v-40l100 70 100-70v40l-100 70ZM80-120q-33 0-56.5-23.5T0-200v-560q0-33 23.5-56.5T80-840h800q33 0 56.5 23.5T960-760v560q0 33-23.5 56.5T880-120H80Zm556-80h244v-560H80v560h4q42-75 116-117.5T360-360q86 0 160 42.5T636-200ZM360-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM182-200h356q-34-38-80.5-59T360-280q-51 0-97 21t-81 59Zm178-280q-17 0-28.5-11.5T320-520q0-17 11.5-28.5T360-560q17 0 28.5 11.5T400-520q0 17-11.5 28.5T360-480Zm120 0Z" />
                </svg>
              </div>
              <div>
                <h5 className="font-weight-bold">E-Mail</h5>
                <p>brindhavanamhostel@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="shadow-md p-4 mb-5 form_body rounded">
            <div className="form-group mb-4">
              <label htmlFor="name" className="font-weight-bold mb-2">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                placeholder="Name"
                value={formData.name}
                name="name"
                onChange={handleInputChange}
              />
              {errors.name && (
                <div className="invalid-feedback">Please enter your name.</div>
              )}

              <label htmlFor="email" className="font-weight-bold my-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="Email Address"
                value={formData.email}
                name="email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              )}

              <label htmlFor="phone" className="font-weight-bold my-2">
                Contact Number
              </label>
              <input
                type="number"
                id="phone"
                className={`form-control ${errors.number ? "is-invalid" : ""}`}
                placeholder="Contact Number"
                value={formData.number}
                name="number"
                onChange={handleInputChange}
              />
              {errors.number && (
                <div className="invalid-feedback">
                  Please enter a valid 10-digit phone number.
                </div>
              )}

              <label htmlFor="message" className="font-weight-bold my-2">
                Message
              </label>
              <textarea
                id="message"
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                rows="3"
                placeholder="Message"
                value={formData.message}
                name="message"
                onChange={handleInputChange}
              />
              {errors.message && (
                <div className="invalid-feedback">
                  Please enter your message.
                </div>
              )}

              <button
                type="submit"
                onClick={handleSubmit}
                className="form_btn px-4 mt-3"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactSection;
