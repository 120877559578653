import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Blogs.css";
import Title from "../../Title/Title";
import axios from "axios";
import { API_BASE_URL } from "../../Api";
import Assets from "../../../Assets/Assets";

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetcher();
  }, []);

  const fetcher = () => {
    axios
      .get(`${API_BASE_URL}blogs`)
      .then((response) => setBlogData(response.data))
      .catch((error) => console.log("Failed to fetch blogs", error));
  };

  return (
    <div className="blogs">
      <div className="position-relative">
        <div>
          <img
            src={Assets.bck_2}
            className="title_bck_img"
            alt="background_img"
          />
          <Title title={"Our"} us={"Blogs"} />
        </div>
      </div>
      {blogData.length > 0 ? (
        <div className="container mt-5">
          <div className="row">
            {blogData
              .slice()
              .reverse()
              .map((blog) => (
                <div key={blog.id} className="col-md-4 mb-4">
                  <Link
                    to={`/blogs/${blog.id}`}
                    state={{ blog }}
                    className="text-reset text-decoration-none"
                  >
                    <div className="card card_blog_1">
                      <img
                        src={"http://localhost:4000/Images/" + blog.image}
                        alt={blog.title}
                        className="card-img-top"
                      />
                      <div className="card-body card_blog_body_1">
                        <h4 className="card-title">{blog.title}</h4>
                        <h6 className="card-subtitle">{blog.subtitle}</h6>
                        <p className="card-text">{blog.subContent}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">Loading Blogs...</div>
      )}
    </div>
  );
};

export default Blogs;
