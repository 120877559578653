import React from "react";
import "./SectionTwo.css";
import { IoStarHalfOutline } from "react-icons/io5";
import { IoStar } from "react-icons/io5";

const SectionTwo = () => {
  const sections = [
    {
      title: "1-Month security deposit",
      imgSrc: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          width="30px"
          fill="#32CD32"
        >
          <path d="M480-480Zm0 400q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 10-.5 20t-1.5 20q-9-2-18.5-3t-19.5-1q-11 0-21 1t-21 3q1-10 1.5-19.5t.5-20.5v-189l-240-90-240 90v189q0 121 68 220t172 132q21-7 41-17t39-23v94q-19 10-39 17.5T480-80Zm194 0q-14 0-24-10t-10-24v-132q0-14 10-24t24-10h6v-40q0-33 23.5-56.5T760-400q33 0 56.5 23.5T840-320v40h6q14 0 24 10t10 24v132q0 14-10 24t-24 10H674Zm46-200h80v-40q0-17-11.5-28.5T760-360q-17 0-28.5 11.5T720-320v40Z" />
        </svg>
      ),
      altText: "deposit",
      text: "Secure your workspace with ease – we require just a one-month security deposit.",
    },
    {
      title: "Flexible sharing option",
      imgSrc: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          width="30px"
          fill="#1E90FF"
        >
          <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" />
        </svg>
      ),
      altText: "share",
      text: "Have great convenience and freedom with roommates with our flexible room sharing option.",
    },
    {
      title: "Friendly ecosystem",
      imgSrc: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          width="30px"
          fill="#4CAF50"
        >
          <path d="M440-690v-100q0-42 29-71t71-29h100v100q0 42-29 71t-71 29H440ZM220-450q-58 0-99-41t-41-99v-140h140q58 0 99 41t41 99v140H220ZM640-90q-39 0-74.5-12T501-135l-33 33q-11 11-28 11t-28-11q-11-11-11-28t11-28l33-33q-21-29-33-64.5T400-330q0-100 70-170.5T640-571h241v241q0 100-70.5 170T640-90Zm0-80q67 0 113-47t46-113v-160H640q-66 0-113 46.5T480-330q0 23 5.5 43.5T502-248l110-110q11-11 28-11t28 11q11 11 11 28t-11 28L558-192q18 11 38.5 16.5T640-170Zm1-161Z" />
        </svg>
      ),
      altText: "ecosystem",
      text: "Forget loneliness, new friends are waiting for you to make new memories with your roommates.",
    },
    {
      title: "Flexible workspace",
      imgSrc: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          width="30px"
          fill="#FF6347"
        >
          <path d="M173-600h614l-34-120H208l-35 120Zm307-60Zm192 140H289l-11 80h404l-10-80ZM160-160l49-360h-89q-20 0-31.5-16T82-571l57-200q4-13 14-21t24-8h606q14 0 24 8t14 21l57 200q5 19-6.5 35T840-520h-88l48 360h-80l-27-200H267l-27 200h-80Z" />
        </svg>
      ),
      altText: "workspace",
      text: "Experience the freedom of adaptable workspaces tailored to your needs. Work smart, work flexible!",
    },
  ];
  return (
    <section className="sec_2_main_page">
      <div className="container">
        <div className="sec_2_intro">
          <div
            className="sec_2_left_show"
          ></div>
          <div className="sec_2_center_intro d-flex flex-column gap-5 align-items-center">
            <h1 className="text-center content">
              Why To Choose
              <span className="color"> Brindhavanam</span> Hi Tech
            </h1>
            <div className="field_icons">
              <h2>4.9 out of 5</h2>
              <div>
                <IoStar className="icon" />
                <IoStar className="icon" />
                <IoStar className="icon" />
                <IoStar className="icon" />
                <IoStarHalfOutline className="icon" />
              </div>
              <p className="text-secondary">Based on 25000+ reviews</p>
            </div>
          </div>
          <div
            className="sec_2_right_show"
          ></div>
        </div>
        <div
          className="sec_2_content mt-5"
          data-aos="fade-down"
          data-aos-easing="linear"
        >
          {sections.map((section, index) => (
            <div
              key={index}
              className="sec_2_content_box p-3 gap-3 align-items-center d-flex"
            >
              <div className="sec_2_icon_bg p-2">
                <div>{section.imgSrc}</div>
              </div>
              <div>
                <h5>{section.title}</h5>
                <p>{section.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
