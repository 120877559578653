import React, { useEffect, useState } from "react";
import Assets from "../../Assets/Assets";
import { CiWallet } from "react-icons/ci";
import { MdOutlineSecurity } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import "./Testimonials.css";
import axios from "axios";
import { API_BASE_URL } from "../Api";

const Testimonials = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);

  useEffect(() => {
    fetcher();
  }, []);

  const fetcher = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}testimonials`);
      setTestimonialsData(response.data);
    } catch (error) {
      console.error("Failed to fetch testimonials", error);
    }
  };
  return (
    <main className="testimonial">
      <section className="section_gap sec_6_testimonial container ">
        <div className="testimonial_info">
          <div className="mb-4">
            <h1 className="content mb-3">
              Look What Our <span className="color">Residents Say's</span>
            </h1>
            <p className="mb-4 sub_content">
              Our residents' experiences speak volumes about the quality of
              living we offer. Here's what they have to say
            </p>
            <div className="testimonial_sub_info">
              <div className="testimonial_short_info_outer">
                <div
                  className="testimonial_short_info_inner"
                  data-aos="zoom-in-down"
                >
                  <CiWallet className="icon" />
                  <div>
                    <h4>Save Your Money</h4>
                    <p>
                      Our affordable pricing helps you save more while enjoying
                      top-notch amenities.
                    </p>
                  </div>
                </div>
                <div
                  className="testimonial_short_info_inner"
                  data-aos="zoom-in-down"
                >
                  <MdOutlineSecurity className="icon" />
                  <div>
                    <h4>24/hrs Security</h4>
                    <p>
                      We ensure your safety with round-the-clock security and
                      surveillance systems.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="certified_info_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src={Assets.certified_icon} alt="logo" width="120px" />
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in-up" data-aos-duration="3000">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards, Autoplay]}
            className="mySwiper"
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            <div>
              {testimonialsData.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper_card">
                    <div className="mb-2">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <img
                          key={i}
                          src={Assets.star}
                          alt="star"
                          className="me-1"
                          style={{ width: "20px" }}
                        />
                      ))}
                    </div>
                    <p className="text-secondary text-sm-start">
                      {testimonial.content}
                    </p>
                    <img
                      src={`http://localhost:4000/Images/${testimonial.image}`}
                      alt={testimonial.name}
                      className="rounded-circle img-fluid"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                    <h5>{testimonial.name}</h5>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </main>
  );
};

export default Testimonials;
