import bed_1 from "./bed_1.jpg";
import bed_2 from "./bed_2.jpg";
import bed_3 from "./bed_3.jpg";
import bed_4 from "./bed_4.jpg";
import bed_5 from "./bed_5.jpg";
import blog_1 from "./blog_1.jpg";
import blog_2 from "./blog_2.jpg";
import blog_3 from "./blog_3.jpg";
import blog_4 from "./blog_4.jpg";
import blog_5 from "./blog_5.jpg";
import blog_6 from "./blog_6.jpg";
import blog_7 from "./blog_7.jpg";
import blog_8 from "./blog_8.jpg";
import blog_9 from "./blog_9.jpg";
import fac_1 from "./fac_1.jpg";
import fac_2 from "./fac_2.jpg";
import fac_3 from "./fac_3.jpg";
import fac_4 from "./fac_4.jpg";
import fac_6 from "./fac_6.jpg";
import fac_7 from "./fac_7.jpg";
import fac_8 from "./fac_8.jpg";
import fac_9 from "./fac_9.jpg";
import hostel_1 from "./hostel_1.jpeg";
import hostel_2 from "./hostel_2.jpeg";
import hostel_3 from "./hostel_3.jpeg";
import hostel_4 from "./hostel_4.jpeg";
import hostel_5 from "./hostel_5.jpeg";
import bed_icon from "./bed_icon.jpeg";
import room_icon from "./room_icon.webp";
import ac_icon from "./ac_icon.png";
import table_icon from "./table_icon.webp";
import washing_machine_icon from "./washing_machine_icon.webp";
import wifi_icon from "./wifi_icon.png";
import cupboard_icon from "./cupboard_icon.webp";
import main_logo from "./main_logo.png";
import parking_1_img from "./parking_1_img.jpg";
import people from "./people.webp";
import wifi_img from "./wifi_img.png";
import eco_img from "./eco_img.png";
import deposit_img from "./deposit_img.png";
import appartment from "./appartment.jpg";
import share_img from "./share_img.png";
import washing_machine_img from "./washing_machine_img.png";
import workspace_img from "./workspace_img.png";
import bk_1 from "./bk_1.jpg";
import bck_1 from "./bck_1.png";
import bck_2 from "./bck_2.png";
import frontlook from "./frontlook.webp";
import breakfast_icon from "./breakfast_icon.png";
import tv_icon from "./tv_icon.jpeg";
import stove_icon from "./stove_icon.jpg";
import main_logo3 from "./main_logo3.png";
import star from "./star.webp";
import certified_icon from "./certified.png";
import bg_status from "./bg-img.webp";

const Assets = {
  bed_1,
  bed_2,
  bed_3,
  bed_4,
  bed_5,
  bed_icon,
  bk_1,
  bck_1,
  bck_2,
  blog_1,
  blog_2,
  blog_3,
  blog_4,
  blog_5,
  blog_6,
  blog_7,
  blog_8,
  blog_9,
  star,
  bg_status,
  certified_icon,
  breakfast_icon,
  ac_icon,
  appartment,
  tv_icon,
  cupboard_icon,
  stove_icon,
  deposit_img,
  eco_img,
  fac_1,
  fac_2,
  fac_3,
  fac_4,
  fac_6,
  fac_7,
  fac_8,
  fac_9,
  hostel_1,
  hostel_2,
  hostel_3,
  hostel_4,
  hostel_5,
  main_logo,
  main_logo3,
  parking_1_img,
  people,
  room_icon,
  share_img,
  table_icon,
  wifi_icon,
  wifi_img,
  workspace_img,
  washing_machine_img,
  washing_machine_icon,
  frontlook,
};

export default Assets;
